import axios from 'axios';
import { useEffect, useState } from 'react';

import { featureFlagStore } from '../../FeatureFlagStore';

export const FeatureFlagProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFeatureFlag = async () => {
            try {
                const response = await axios.get('/api/auth/feature-flags');
                featureFlagStore.setFeatureFlags(response.data);
            } catch (err) {
                featureFlagStore.setFeatureFlags({});
            } finally {
                setLoading(false);
            }
        };
        fetchFeatureFlag();
    }, []);

    if (loading) return <></>;
    return <>{children}</>;
};
